import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../styles/layout.css"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      throttleTimeout: null,
      scrolled: false,
    }
  }

  componentDidMount() {
    if (this.props.homePage === true) {
      window.addEventListener("scroll", this.handleScroll)
      this.handleScroll()
    }
  }

  componentWillUnmount() {
    if (this.props.homePage === true) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll = () => {
    if (this.state.throttleTimeout === null) {
      this.setState({
        ...this.state,
        throttleTimeout: setTimeout(this.toggleBodyClass, 300),
       })
    }
  }

  toggleBodyClass = () => {
    if (this.state.scrolled && window.scrollY <= 10) {
      this.setState({
        throttleTimeout: null,
        scrolled: false,
      })
    } else if (!this.state.scrolled && window.scrollY > 10) {
      this.setState({
        throttleTimeout: null,
        scrolled: true,
      })
    } else {
      this.setState({
        ...this.state,
        throttleTimeout: null,
      })
    }
  }

  render() {
    const {
      children,
      onHideNav,
      onShowNav,
      showNav,
      siteTitle,
      navMenuItems,
      textWhite = true,
      homePage,
    } = this.props
    const { scrolled } = this.state

    return (
      <>
        <Header
          navMenuItems={navMenuItems}
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          scrolled={
            homePage === true
              ? scrolled
              : true
          }
          textWhite={textWhite}
        />
        <>{children}</>
        <Footer siteTitle={siteTitle} />
      </>
    )
  }
}

export default Layout
