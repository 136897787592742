import { Link } from "gatsby";
import React from "react";
import CTALink from "./CTALink";

const Header = ({ showNav = true, scrolled, navMenuItems = [], textWhite = true }) => {
  let headerClass = "fixed w-full z-30 top-0 text-white";
  headerClass += scrolled ? " bg-black" : " bg-black bg-opacity-25";

  let navActionClass =
    "mx-auto lg:mx-0 hover:underline font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 text-white";

  let navContentClass ="flex-grow flex items-center w-auto block mt-0 p-0 z-20 bg-transparent text-white";

  let titleClass = "toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-white";

  return (
    <nav id="header"
      style={{
          fontFamily: 'Montserrat',
      }}
      className={headerClass}
    >
      <div className="w-full container mx-auto flex flex-col sm:flex-row flex-wrap items-center justify-between mt-0 py-2">
        <div className="sm:pl-2 flex items-center">
          <Link id="siteTitle" className={titleClass} to="/">
            <svg
              className="h-12 fill-current inline"
              xmlns="http://www.w3.org/2000/svg"
              width="285"
              height="160"
              viewBox="0 0 173.34 97.946"
            >
              <g id="DOLE" transform="translate(0 0.006)">
                <path className="plane-take-off" id="Path_1" data-name="Path 1" d="M64.56,5.64a12.25,12.25,0,0,0-9.17,3.87,13,13,0,0,0-3.73,9.37,13,13,0,0,0,3.73,9.38,12.81,12.81,0,0,0,18.33,0,13,13,0,0,0,3.74-9.38,13,13,0,0,0-3.74-9.37,12.23,12.23,0,0,0-9.16-3.87Z" fill="none"/>
                <path className="plane-take-off" id="Path_2" data-name="Path 2" d="M12.33,5.69H6.16v25H13q6.63,0,10.16-3.16t3.52-9.32Q26.69,5.69,12.33,5.69Z" fill="none"/>
                <path className="plane-take-off" id="Path_3" data-name="Path 3" d="M27.63,4.78Q22.35,0,12.54,0H0V36.51H12.12q10.55,0,15.67-5t5.12-13.48Q32.91,9.57,27.63,4.78ZM23.17,27.55Q19.63,30.72,13,30.71H6.16v-25h6.17q14.35,0,14.36,12.54Q26.69,24.4,23.17,27.55Z" />
                <path className="plane-take-off" id="Path_4" data-name="Path 4" d="M64.56,0A18.82,18.82,0,0,0,50.87,5.41a18,18,0,0,0-5.53,13.47,18.08,18.08,0,0,0,5.53,13.48,18.85,18.85,0,0,0,13.69,5.4,18.83,18.83,0,0,0,13.68-5.4,18.09,18.09,0,0,0,5.54-13.48A18.05,18.05,0,0,0,78.24,5.41,18.79,18.79,0,0,0,64.56,0Zm9.16,28.26a12.81,12.81,0,0,1-18.33,0,13,13,0,0,1-3.73-9.38,13,13,0,0,1,3.73-9.37,12.79,12.79,0,0,1,18.33,0,13,13,0,0,1,3.74,9.37,13,13,0,0,1-3.74,9.38Z" />
                <path className="plane-take-off" id="Path_5" data-name="Path 5" d="M152.02,92.09V61.43h-.05v-.12H130.65V30.65h-6.16v.01H103.76V0H97.6V36.51h26.89V67.17h21.37V97.94h27.48V92.09Z" />
                <path className="plane-take-off" id="Path_6" data-name="Path 6" d="M172.41,30.71H152.3V20.95h17.5V15.46H152.3V5.8h19.48V0H146.14V36.51h26.27Z" />
              </g>
            </svg>
          </Link>
        </div>

        {navMenuItems && (
          <div className={navContentClass} id="nav-content">
            <ul className="flex justify-end flex-1 items-center">
              {navMenuItems.map((i) => (
                <li className="mr-3">
                  <CTALink {...i} buttonActionClass={navActionClass} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
