import { Link } from "gatsby";
import React from "react";

const Footer = ({ siteTitle }) => (
  <footer
    className="bg-white"
    style={{
        fontFamily: 'Montserrat',
        backgroundColor: '#E4BD00'
      }}
  >
    <div className="container mx-auto px-8">
      <div className="w-full flex flex-col md:flex-row py-6">
        <div className="flex-1 -mt-6 pr-6 pb-6">
        <Link
          to="/"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="200" height="115" viewBox="0 0 311.5 179.384">
            <path id="Path_12" data-name="Path 12" d="M74.966,67.072A22.133,22.133,0,0,0,58.4,74.064a24.662,24.662,0,0,0,0,33.876,23.144,23.144,0,0,0,33.118,0,24.608,24.608,0,0,0,0-33.876,22.1,22.1,0,0,0-16.55-6.992Z" transform="translate(41.677 54.106)" fill="none"/>
            <path id="Path_13" data-name="Path 13" d="M17.308,67.12H6.16v45.168H18.518q11.979,0,18.356-5.709t6.36-16.839Q43.252,67.12,17.308,67.12Z" transform="translate(4.97 54.148)" fill="none"/>
            <path id="Path_14" data-name="Path 14" d="M49.92,70.066q-9.54-8.636-27.264-8.636H0v65.964H21.9q19.061,0,28.312-9.034T59.46,94.078Q59.46,78.721,49.92,70.066Zm-8.058,41.176q-6.4,5.691-18.375,5.673H11.13V71.746H22.277q25.927,0,25.945,22.657Q48.222,105.5,41.862,111.242Z" transform="translate(0 49.558)" fill="#fff"/>
            <path id="Path_15" data-name="Path 15" d="M80.071,61.525a34.057,34.057,0,0,0-24.734,9.756,32.666,32.666,0,0,0-9.991,24.355,32.521,32.521,0,0,0,9.991,24.337,34,34,0,0,0,24.734,9.774,33.949,33.949,0,0,0,24.716-9.774A32.612,32.612,0,0,0,114.8,95.636a32.684,32.684,0,0,0-10.009-24.355,34.021,34.021,0,0,0-24.716-9.756Zm16.55,51.04a23.108,23.108,0,0,1-33.118,0,24.662,24.662,0,0,1,0-33.876,23.144,23.144,0,0,1,33.118,0A24.608,24.608,0,0,1,96.621,112.565Z" transform="translate(36.572 49.626)" fill="#fff"/>
            <path id="Path_16" data-name="Path 16" d="M157.813,166.383V110.988h-.09v-.217H119.2V55.377h-11.13v.018H70.62V0H59.49V65.964h48.583v55.395h38.61v55.593h49.649V166.383Z" transform="translate(47.993)" fill="#fff"/>
            <path id="Path_17" data-name="Path 17" d="M193.6,116.915H157.27V99.263h31.618v-9.9H157.27V71.909h35.2V61.43H146.14v65.964H193.6Z" transform="translate(117.897 49.558)" fill="#fff"/>
          </svg>
        </Link>
        </div>

        <div className="flex-1">
          <p className="uppercase text-white md:mb-6">ODKAZY</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                  to="/program"
                  className="no-underline hover:underline text-gray-800 "
                >
                  Program
              </Link>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                  to="/novinky"
                  className="no-underline hover:underline text-gray-800 "
                >
                  Novinky
              </Link>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                  to="/klub"
                  className="no-underline hover:underline text-gray-800 "
                >
                  Klub
              </Link>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <Link
                  to="/kontakt"
                  className="no-underline hover:underline text-gray-800 "
                >
                  Kontakt
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex-1">
          <p className="uppercase text-white md:mb-6">SLEDUJTE NÁS</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href="https://www.instagram.com/"
                className="no-underline hover:underline text-gray-800 "
              >
                Instagram
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href="https://www.facebook.com/"
                className="no-underline hover:underline text-gray-800 "
              >
                Facebook
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-1">
          <p className="uppercase text-white md:mb-6">NAŠE DALŠÍ PODNIKY</p>
          <ul className="list-reset mb-6">
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href="http://www.srdcovkamonika.cz/"
                className="no-underline hover:underline text-gray-800 "
              >
                Srdcovka Monika
              </a>
            </li>
            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href="https://www.restauracedrevak.cz/"
                className="no-underline hover:underline text-gray-800 "
              >
                Restaurace Dřevák
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
